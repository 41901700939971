/* istanbul ignore file */
import download from 'downloadjs';
import Snackbar from './snackbar';

export const apiFetch = async (url, method, data, handleError = false) => {
  const vimeoRequest = url.includes('vimeo');
  const _res = await fetch(url, {
    method,
    body: JSON.stringify(data),
    headers: vimeoRequest ? {
      Accept: 'application/json',
    } : {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': (document.querySelector('meta[name=csrf-token]') || {}).content,
    },
  });

  if (handleError && !_res.ok) {
    await Snackbar.show((await _res.json()).message || 'Some error occurred');
  }

  return _res.json();
};

const apiFetchRaw = async (url, method, data, handleError = false) => {
  const _res = await fetch(url, {
    method,
    body: data,
    headers: {
      Accept: 'application/json',
      'X-CSRF-Token': (document.querySelector('meta[name=csrf-token]') || {}).content,
    },
  });

  if (handleError && !_res.ok) {
    await Snackbar.show((await _res.json()).message || 'Some error occurred');
  }

  return _res.json();
};

const _downloadPDF = (url, data) => {
  let filename = '';
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
      'X-CSRF-Token': (document.querySelector('meta[name=csrf-token]') || {}).content,
    },
    body: data ? JSON.stringify(data) : undefined,
    responseType: 'arraybuffer',
  }).then((res) => {
    if (res.status === 200) {
      const disposition = res.headers.get('content-disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
          filename = decodeURIComponent(matches[1].replace(/['"]/g, ''));
        }
      }
      return res.blob();
    }
    throw res;
  }).then((blob) => download(blob, filename, 'application/octet-stream'));
};

const _put = (url, data) => apiFetch(url, 'PUT', data);
const _post = (url, data) => apiFetch(url, 'POST', data);
const _get = (url) => apiFetch(url, 'GET');
const _delete = (url) => apiFetch(url, 'DELETE');

const _adminPut = (url, data) => apiFetch(url, 'PUT', data, true);
const _adminPost = (url, data) => apiFetch(url, 'POST', data, true);
const _adminGet = (url) => apiFetch(url, 'GET', undefined, true);
const _adminDelete = (url) => apiFetch(url, 'DELETE', true);

const api = {
  send_feedback: (text) => _post(`/marketing/feedback?text=${text}`),
  register: (data) => _post('/api/registrations', {
    registration_form: data,
    plan_name: data.plan.name,
  }),
  check_email: (email) => _post(`/api/registrations/check_email?email=${email}`),
  reset_password: (data) => _post('/api/registrations/reset_password', data),
  subscribe: (data) => _post('/api/subscriptions', {
    stripe_token: data.stripe_token,
    promo_code: data.promo_code,
    plan_name: data.plan_name,
    interval: data.interval,
  }),
  academy_subscribe: (stripe_token, promo_code, interval) => _post('/api/subscriptions/academy', { stripe_token, promo_code, interval }),
  cancel_subscription: (id) => _delete(`/api/subscriptions/${id}`),
  promo_code: (code) => _get(`/api/subscriptions/promo_code?code=${code}`),
  case_digests: {
    favorite: (id) => _post(`/api/case_law/${id}/favorite`),
    multiple_favorite: (ids) => _post('/api/case_law/favorites', { case_ids: ids }),
    unfavorite: (id) => _delete(`/api/case_law/${id}/favorite`),
    downloadPDF: (id) => _downloadPDF(`/api/case_law/${id}/pdf`),
    bulkTags: (data) => _post('/api/case_law/bulk_tag', data),
  },
  cite_lists: {
    get: ({ withShared = false } = {}) => _get(`/api/cite_lists?shared=${withShared ? 1 : 0}`),
    getCiteList: (id) => _get(`/api/cite_lists/${id}`),
    exportCiteList: (id, data) => _post(`/api/cite_lists/${id}/export_pdf`, data),
    exportCiteListPdf: (id, data) => _post(`/api/cite_lists/${id}/export_citelist_pdf`, data),
    post: (data) => _post('/api/cite_lists', data),
    delete: (id) => _delete(`/api/cite_lists/${id}`),
    update: (id, data) => _put(`/api/cite_lists/${id}`, data),
    share: (id, data) => _post(`/api/cite_lists/${id}/share`, data),
    unshare: (id, data) => _post(`/api/cite_lists/${id}/unshare`, data),
    dynamic_to_static: (id, data) => _post(`/api/cite_lists/${id}/dynamic_to_static`, data),
    favorite: (id) => _post(`/api/cite_lists/${id}/favorite`),
  },
  cite_list_cases: {
    get: (citeListId, { page } = {}, sort_by = '') => _get(`/api/cite_list_cases?cite_list_id=${citeListId}&page=${page}&sort_by=${sort_by}`),
    post: (data) => _post('/api/cite_list_cases', data),
    mass_unassign: (data) => _post('/api/cite_list_cases/mass_unassign', data),
    mass_assign: (data) => _post('/api/cite_list_cases/mass_assign', data),
  },
  rules: {
    downloadPDF: (id) => _downloadPDF(`/api/rules/${id}/download_pdf`),
    favorite: (id) => _post(`/api/rules/${id}/favorite`),
    federal: {
      get: () => _get('/api/rules'),
      favorite: (id) => _post(`/api/federal_rules/${id}/favorite`),
      unfavorite: (id) => _delete(`/api/federal_rules/${id}/favorite`),
    },
    aaa: {
      get: () => _get('/api/rules/aaa'),
      favorite: (id) => _post(`/api/aaa_rules/${id}/favorite`),
      unfavorite: (id) => _delete(`/api/aaa_rules/${id}/favorite`),
    },
    international: {
      get: () => _get('/api/rules/international'),
      favorite: (id) => _post(`/api/international_rules/${id}/favorite`),
      unfavorite: (id) => _delete(`/api/international_rules/${id}/favorite`),
    },
    state: {
      getRule: (id) => _get(`/api/state_rules/${id}`),
      get: (state) => _get(`/api/rules/state_courts?state=${state}`),
      favorite: (id) => _post(`/api/state_rules/${id}/favorite`),
      unfavorite: (id) => _delete(`/api/state_rules/${id}/favorite`),
    },
    district: {
      get: (districtState) => _get(`/api/rules/district_courts?districtState=${districtState}`),
      favorite: (id) => _post(`/api/district_rules/${id}/favorite`),
      unfavorite: (id) => _delete(`/api/district_rules/${id}/favorite`),
    },
    regulatory: {
      get: () => _get('/api/rules/regulatory'),
      favorite: (id) => _post(`/api/regulatory_rules/${id}/favorite`),
      unfavorite: (id) => _delete(`/api/regulatory_rules/${id}/favorite`),
    },
    aba: {
      get: () => _get('/api/rules/aba'),
      favorite: (id) => _post(`/api/aba_model_rules/${id}/favorite`),
      unfavorite: (id) => _delete(`/api/aba_model_rules/${id}/favorite`),
    },
  },
  glossary: {
    get: () => _get('/api/glossary_terms'),
    getTerm: (id) => _get(`/api/glossary_terms/${id}`),
    favorite: (id) => _post(`/api/glossary_terms/${id}/favorite`),
    unfavorite: (id) => _delete(`/api/glossary_terms/${id}/favorite`),
  },
  checklists: {
    pdf: (id, data) => _downloadPDF(`/api/checklists/${id}/export_pdf`, data),
    favorite: (id) => _post(`/api/checklists/${id}/favorite`),
    unfavorite: (id) => _delete(`/api/checklists/${id}/favorite`),
  },
  forms: {
    pdf: (id) => _downloadPDF(`/api/forms/${id}/export_pdf`),
    favorite: (id) => _post(`/api/forms/${id}/favorite`),
    unfavorite: (id) => _delete(`/api/forms/${id}/favorite`),
  },
  favorites: {
    get: ({ withPublishedRevision } = {}) => _get(`/api/favorites?${withPublishedRevision ? 'with_published_revision=1' : ''}`),
  },
  media_items: {
    progress: (id, value) => _post(`/api/media_items/${id}/progress`, { progress: value }),
    favorite: (id) => _post(`/api/media_items/${id}/favorite`),
    unfavorite: (id) => _delete(`/api/media_items/${id}/favorite`),
    vimeo_thumbnail: (url) => _get(`https://vimeo.com/api/oembed.json?url=${url}`),
  },
  history: {
    paginatedRules: (startDate, endDate, page, perPage) => _get(`/api/history/paginated_rule_history/?start_date=${startDate}&end_date=${endDate}&page=${page}&per_page=${perPage}`),
    paginatedSearch: (startDate, endDate, page, perPage) => _get(`/api/history/paginated_search_history/?start_date=${startDate}&end_date=${endDate}&page=${page}&per_page=${perPage}`),
    paginatedChecklistsAndForms: (startDate, endDate, page, perPage) => _get(`/api/history/paginated_checklists_and_forms_history/?start_date=${startDate}&end_date=${endDate}&page=${page}&per_page=${perPage}`),
    paginatedGlossaryTerms: (startDate, endDate, page, perPage) => _get(`/api/history/paginated_glossary_terms_history/?start_date=${startDate}&end_date=${endDate}&page=${page}&per_page=${perPage}`),
    allPaginated: (startDate, endDate, page, perPage) => _get(`/api/history/all_paginated_history/?start_date=${startDate}&end_date=${endDate}&page=${page}&per_page=${perPage}`),
  },
  account: {
    updatePaymentMethod: (data) => _post('/api/account/payment_method', data),
    user: {
      put: (data) => _put('/api/account', data),
      updateDownloadLimit: (data) => _post('/api/account/update_download_limit', data),
      settings: {
        put: (data) => _put('/api/settings', data),
        twoFactorDisable: () => _delete('/two_factor_settings'),
        twoFactorEnable: () => _post('/two_factor_settings'),
      },
    },
  },
  admin: {
    media_items: {
      getAll: ({
        page = 1, title = '', type = '', tagIds = [],
        sortBy = '', sortDir = '',
      }) => {
        const _params = new URLSearchParams();
        _params.append('title', title);
        _params.append('page', page);
        _params.append('type', type);
        tagIds.forEach((t) => _params.append('tag_ids', t));
        _params.append('sort_by', sortBy);
        _params.append('sort_dir', sortDir);

        return _adminGet(`/api/admin/media_items?${_params.toString()}`);
      },
      save_chapters_order: (data) => _adminPost('/api/admin/media_items/save_chapters_order', data),
      delete: (id) => _adminDelete(`/api/admin/media_items/${id}`),
    },
    media_categories: {
      save: (data) => _adminPost('/api/admin/media_categories/save', data),
    },
    categories: {
      getAll: () => _adminGet('/api/admin/categories'),
      get: (id) => _adminGet(`/api/admin/categories/${id}`),
      create: (data) => _adminPost('/api/admin/categories', data),
      update: (id, data) => _adminPut(`/api/admin/categories/${id}`, data),
      delete: (id) => _adminDelete(`/api/admin/categories/${id}`),
    },
    tips: {
      getAll: () => _adminGet('/api/admin/tips'),
      get: (id) => _adminGet(`/api/admin/tips/${id}`),
      create: (data) => _adminPost('/api/admin/tips', data),
      update: (id, data) => _adminPut(`/api/admin/tips/${id}`, data),
      delete: (id) => _adminDelete(`/api/admin/tips/${id}`),
    },
    tags: {
      getAll: () => _adminGet('/api/admin/tags'),
      get: (id) => _adminGet(`/api/admin/tags/${id}`),
      create: (data) => _adminPost('/api/admin/tags', data),
      update: (id, data) => _adminPut(`/api/admin/tags/${id}`, data),
      delete: (id) => _adminDelete(`/api/admin/tags/${id}`),
    },
    checklists: {
      get: ({
        page, category = '', published = '', sortBy = '', sortDir = '',
      }) => _adminGet(`/api/admin/checklists?page=${page}&category=${category}&published=${published}&sort=${sortBy}&sort_by=${sortDir}`),
      create: (data) => _adminPost('/api/admin/checklists', data),
      update: (id, data) => _adminPut(`/api/admin/checklists/${id}`, data),
      publish: (id) => _adminPost(`/api/admin/checklists/${id}/publish`),
      unpublish: (id) => _adminPost(`/api/admin/checklists/${id}/unpublish`),
      delete: (id) => _adminDelete(`/api/admin/checklists/${id}`),
    },
    templates: {
      get: ({
        page, category = '', published = '', sortBy = '', sortDir = '',
      }) => _adminGet(`/api/admin/templates?page=${page}&category=${category}&published=${published}&sort=${sortBy}&sort_by=${sortDir}`),
      create: (data) => apiFetchRaw('/api/admin/templates', 'POST', data, true),
      update: (id, data) => apiFetchRaw(`/api/admin/templates/${id}`, 'PUT', data, true),
      publish: (id) => _adminPost(`/api/admin/templates/${id}/publish`),
      unpublish: (id) => _adminPost(`/api/admin/templates/${id}/unpublish`),
      delete: (id) => _adminDelete(`/api/admin/templates/${id}`),
    },
    users: {
      get: ({
        page, pageSize = '', accountId = '', name = '', email = '', accountName = '', accountType = '', sortBy = '', sortDir = '',
      }) => _adminGet(`/api/admin/users?page=${page}&page_size=${pageSize}&name=${name}&email=${email}&account_name=${accountName}&account_type=${accountType}&account_id=${accountId}&sort=${sortBy}&sort_by=${sortDir}`),
      create: (data) => _adminPost('/api/admin/users', data),
      update: (id, data) => _adminPut(`/api/admin/users/${id}`, data),
      delete: (id) => _adminDelete(`/api/admin/users/${id}`),
      resetPassword: (id) => _adminPost(`/api/admin/users/${id}/reset_password`),
      sendConfirmation: (id) => _adminPost(`/api/admin/users/${id}/send_confirmation`),
      enable: (id) => _adminPost(`/api/admin/users/${id}/enable`),
      disable: (id) => _adminPost(`/api/admin/users/${id}/disable`),
      twoFactorSettings: (id) => _adminPost(`/api/admin/users/${id}/two_factor_settings`),
    },
    user_licenses: {
      get: ({
        page = 1, pageSize = '', name = '', accountName = '', accountType = '', sortBy = '', sortDir = '', subscriptionStatus = '', plan = '',
      }) => _adminGet(`/api/admin/user_licenses?page=${page}&page_size=${pageSize}&name=${name}&account_name=${accountName}&account_type=${accountType}&plan=${plan}&subscription_status=${subscriptionStatus}&sort=${sortBy}&sort_by=${sortDir}`),
    },
    accounts: {
      get: ({
        page, name = '', type = '', category = '', plan = '', sortBy = '', sortDir = '', accountStatus = '',
      }) => _adminGet(`/api/admin/accounts?page=${page}&name=${name}&account_type=${type}&account_status=${accountStatus}&category=${category}&plan=${plan}&sort=${sortBy}&sort_by=${sortDir}`),
      create: (data) => _adminPost('/api/admin/accounts', data),
      update: (id, data) => _adminPut(`/api/admin/accounts/${id}`, data),
      delete: (id) => _adminDelete(`/api/admin/accounts/${id}`),
    },
    subscriptions: {
      update: (id, data) => _adminPut(`/api/admin/subscriptions/${id}`, data),
      cancel: (id) => _adminDelete(`/api/admin/subscriptions/${id}`),
      reactivate: (id) => _adminPost(`/api/admin/subscriptions/${id}/reactivate`),
    },
  },
};

export default api;
