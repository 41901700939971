// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="federal-rules"
>
  <div
    className="federal-rule-list"
  >
    <div
      className="title"
    >
      Federal Rule Category #1
      <span
        className="selected-category-count"
      >
        2
      </span>
      <div
        className="case-search"
      >
        <input
          onChange={[Function]}
          placeholder="Search Issues, try: Sanctions"
          type="text"
          value=""
        />
        <svg
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 16 16"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7 13.3l-3.81-3.83A5.93 5.93 0 0 0 13 6c0-3.31-2.69-6-6-6S1 2.69 1 6s2.69 6 6 6c1.3 0 2.48-.41 3.47-1.11l3.83 3.81c.19.2.45.3.7.3.25 0 .52-.09.7-.3a.996.996 0 0 0 0-1.41v.01zM7 10.7c-2.59 0-4.7-2.11-4.7-4.7 0-2.59 2.11-4.7 4.7-4.7 2.59 0 4.7 2.11 4.7 4.7 0 2.59-2.11 4.7-4.7 4.7z"
            fillRule="evenodd"
          />
        </svg>
        <span
          style={
            Object {
              "flex": 1,
            }
          }
        />
      </div>
    </div>
    <div
      className="results"
    >
      <a
        className="result"
        href="/undefined_rules/2"
      >
        <div
          className="top"
        >
          <div
            className="result-number"
          >
            <div
              style={
                Object {
                  "flex": "none",
                  "maxWidth": "50%",
                }
              }
            >
              Number for Rule #1 - 
            </div>
             
            <div
              style={
                Object {
                  "fontWeight": 400,
                }
              }
            >
              This is a Title for Rule #1
            </div>
          </div>
          <svg
            className="initially-hidden"
            fill="currentColor"
            height="1em"
            onClick={[Function]}
            stroke="currentColor"
            strokeWidth="0"
            style={
              Object {
                "color": undefined,
              }
            }
            viewBox="0 0 384 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
            />
          </svg>
        </div>
        <div
          className="result-tags"
        >
          <div
            className="tag btn btn-flat"
          >
            tag A
          </div>
          <div
            className="tag btn btn-flat"
          >
            tag B
          </div>
        </div>
      </a>
      <a
        className="result"
        href="/undefined_rules/3"
      >
        <div
          className="top"
        >
          <div
            className="result-number"
          >
            <div
              style={
                Object {
                  "flex": "none",
                  "maxWidth": "50%",
                }
              }
            >
              Number for Rule #2 - 
            </div>
             
            <div
              style={
                Object {
                  "fontWeight": 400,
                }
              }
            >
              This is a Title for Rule #2
            </div>
          </div>
          <svg
            className="initially-hidden"
            fill="currentColor"
            height="1em"
            onClick={[Function]}
            stroke="currentColor"
            strokeWidth="0"
            style={
              Object {
                "color": undefined,
              }
            }
            viewBox="0 0 384 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
            />
          </svg>
        </div>
        <div
          className="result-tags"
        >
          <div
            className="tag btn btn-flat"
          >
            tag C
          </div>
          <div
            className="tag btn btn-flat"
          >
            tag D
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
`;
