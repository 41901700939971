import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FadeLoader } from 'react-spinners';
import {
  FaPlus, FaArrowDown, FaBookmark,
} from 'react-icons/all';
import api from '../../services/api';
import Map from '../search/src/Map';
import AddToCiteListSection from '../cite_lists/AddToCiteListSection';
import TagList from '../tags/TagList';
import { DecisionDateFormat } from '../../services/constants';
import CaseDigestPreview from '../case_digest/CaseDigestPreview';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      favorites: {},
      citeModalOpen: false,
      citeModalResult: null,
      bookmarksLoading: true,
      mapType: 'circuit',
    };
  }

  componentDidMount() {
    this.updateFavorites();
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => window.history.go(1);
  }

  setMapType = (type) => {
    this.setState({ mapType: type });
  };

  getBookmarkLink(category, favorite) {
    if (category === 'checklists_and_forms' && favorite?.published_revision?.checklist_id) return `/checklists/${favorite.published_revision.checklist_id}`;
    if (category === 'checklists_and_forms' && favorite?.published_revision?.template_id) return `/forms/${favorite.published_revision?.template_id}`;
    if (category === 'case_digests') return `/case_law/${favorite?.id}`;
    if (category === 'rules') {
      if (favorite?.class_name === 'AbaModelRule') return `/aba_model_rules/${favorite.id}`;
      if (favorite.class_name === 'AaaRule') return `/aaa_rules/${favorite.rule_id}`;
      if (favorite.class_name === 'InternationalRule') return `/international_rules/${favorite.id}`;
      if (favorite.class_name === 'StateRule') return `/state_rules/${favorite.id}`;
      if (favorite.class_name === 'FederalRule') return `/federal_rules/${favorite.id}`;
      if (favorite.class_name === 'DistrictRule') return `/district_rules/${favorite.id}`;
      if (favorite.class_name === 'RegulatoryRule') return `/regulatory_rules/${favorite.id}`;
    }
    if (category === 'glossary_terms') return `/glossary_terms?term=${favorite?.published_revision?.id}`;
    if (category === 'cite_lists') return `/cite_lists/${favorite.id}`;
    return '';
  }

  toggleBookmark = (e, category, result) => {
    if (e) e.preventDefault();
    const { favorites } = this.state;
    let endpoint = '';

    if (category === 'case_digests') {
      endpoint = api.case_digests;
    } else if (category === 'forms') {
      endpoint = api.forms;
    } else if (category === 'checklists_and_forms') {
      if (result.class_name === 'Template') endpoint = api.forms;
      else if (result.class_name === 'Checklist') endpoint = api.checklists;
    } else if (category === 'glossary_terms') {
      endpoint = api.glossary;
    } else if (category === 'rules') {
      if (result.class_name === 'FederalRule') {
        endpoint = api.rules.federal;
      } else if (result.class_name === 'StateRule') {
        endpoint = api.rules.state;
      } else if (result.class_name === 'DistrictRule') {
        endpoint = api.rules.district;
      } else if (result.class_name === 'AbaModelRule') {
        endpoint = api.rules.aba;
      } else if (result.class_name === 'RegulatoryRule') {
        endpoint = api.rules.regulatory;
      } else if (result.class_name === 'AaaRule') {
        endpoint = api.rules.aaa;
      } else if (result.class_name === 'InternationalRule') {
        endpoint = api.rules.international;
      }
    }

    if (category === 'cite_lists') {
      api.cite_lists.favorite(result.id).then(() => {
        this.updateFavorites();
      });

      return;
    }

    if (!endpoint) return;

    if ((favorites[category] || []).some((item) => item.id === result.id)) {
      endpoint.unfavorite(result.id).then(() => this.updateFavorites());
      return;
    }
    endpoint.favorite(result.id).then(() => this.updateFavorites());
  };

  updateFavorites = () => {
    api.favorites.get({ withPublishedRevision: true }).then((result) => {
      this.setState({ favorites: result.favorites, bookmarksLoading: false });
    });
  };

  transformCategory = (category) => {
    if (category === 'rules') return 'Rules';
    if (category === 'glossary_terms') return 'Glossary';
    if (category === 'checklists') return 'Checklists';
    if (category === 'forms') return 'Forms';
    if (category === 'cite_lists') return 'Cite Lists';
    return null;
  };

  transformRuleType = (ruleType) => {
    if (ruleType === 'AbaModelRule') return 'ABA';
    if (ruleType === 'StateRule') return 'State';
    if (ruleType === 'FederalRule') return 'Federal';
    if (ruleType === 'DistrictRule') return 'District';
    if (ruleType === 'RegulatoryRule') return 'Regulatory';
    return null;
  };

  openCiteModal = (e, result) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ citeModalOpen: true, citeModalResult: result });
  };

  closeModal = ({ citeModalOpen = false }) => {
    this.setState({
      citeModalOpen,
      citeModalResult: null,
    });
  };

  downloadPDF = (e, result) => {
    e.preventDefault();
    api.case_digests.downloadPDF(result.id).catch(() => alert('Download failed'));
  };

  renderCasesMap() {
    const { courtCounts, federalCourts, stateCourts } = this.props;
    const { mapType } = this.state;
    return <Map type={mapType} data={courtCounts} federalCourts={federalCourts} stateCourts={stateCourts} />;
  }

  renderBookmarksTotal() {
    const { favorites } = this.state;
    const sum = Object.keys(favorites).reduce((s, c) => s + favorites[c].length, 0);
    return `${sum} ${sum !== 1 ? 'items' : 'item'}`;
  }

  renderBookmarks() {
    const { favorites, bookmarksLoading } = this.state;
    return (
      <div>
        { bookmarksLoading && (
          <div className="loader">
            <FadeLoader color="rgba(0, 14, 87, 1)" />
            <div className="name">Loading</div>
          </div>
        ) }
        {
          Object.keys(favorites).map((category) => favorites[category].map((favorite) => (
            <a className="bookmark" key={favorite.id} href={this.getBookmarkLink(category, favorite)}>
              { category === 'case_digests' ? (
                <>
                  <div className="bookmark-name">
                    { favorite.published_revision.name}
                  </div>
                  <div className="cite">
                    {favorite.published_revision.cite}
                  </div>
                  <div className="cite">
                    {favorite.published_revision.decision_date && moment(favorite.published_revision.decision_date).format(DecisionDateFormat)}
                  </div>
                  {/* { */}
                  {/*  favorite.published_revision.is_per_curiam && ( */}
                  {/*    <> */}
                  {/*      Per Curiam */}
                  {/*    </> */}
                  {/*  ) */}
                  {/* } */}
                  {/* { */}
                  {/*  favorite.published_revision.is_panel && ( */}
                  {/*    <> */}
                  {/*      Panel members: */}
                  {/*      {' '} */}
                  {/*      {(favorite.published_revision.panel_members || []).map((member, idx) => ( */}
                  {/*        <> */}
                  {/*          <a className="cite" href={`/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=${member}`}> */}
                  {/*            {member} */}
                  {/*          </a> */}
                  {/*          { idx < favorite.published_revision.panel_members.length - 1 ? ', ' : '' } */}
                  {/*        </> */}
                  {/*      ))} */}
                  {/*    </> */}
                  {/*  ) */}
                  {/* } */}
                  {
                    Boolean(favorite.published_revision.judge) && (
                      <>
                        <a className="cite" href={`/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=${favorite.published_revision.judge}`}>
                          {favorite.published_revision.judge}
                        </a>
                        {`${favorite.published_revision.judge === 'Panel' && !favorite.published_revision.judge_type ? '' : ','}
                          \u00a0${favorite.published_revision.judge_type}`}
                      </>
                    )
                  }
                  <TagList tags={favorite.published_revision.tags} clickable />
                  <div className="result-buttons">
                    <button className="result-button" type="button" onClick={(e) => this.openCiteModal(e, [favorite.id])}>
                      <FaPlus />
                      To Cite List
                    </button>

                    <button
                      className="result-button"
                      onClick={(e) => this.downloadPDF(e, favorite)}
                    >
                      <FaArrowDown />
                      PDF
                    </button>

                    <button
                      className="result-button bookmark-button"
                      onClick={(e) => this.toggleBookmark(e, 'case_digests', favorite)}
                    >
                      <FaBookmark />
                    </button>
                  </div>
                </>
              ) : category === 'cite_lists' ? (
                <>
                  <div className="bookmark-category">{this.transformCategory(category)}</div>
                  <div className="bookmark-name">
                    { favorite.name }
                  </div>
                  <div className="result-buttons">
                    <button
                      className="result-button bookmark-button"
                      onClick={(e) => this.toggleBookmark(e, category, favorite)}
                    >
                      <FaBookmark />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="bookmark-category">{this.transformCategory(category)}</div>
                  <div className="bookmark-name">
                    { favorite.published_revision.name || favorite.published_revision.number }
                  </div>
                  <div className="bookmark-type">
                    {
                      category === 'rules'
                        ? this.transformRuleType(favorite.class_name)
                        : (favorite.published_revision.categories || []).map((c) => c.name).join(', ')
                    }
                  </div>
                  <div className="result-buttons">
                    <button
                      className="result-button bookmark-button"
                      onClick={(e) => this.toggleBookmark(e, category, favorite)}
                    >
                      <FaBookmark />
                    </button>
                  </div>
                </>
              )}
            </a>
          )))
        }
      </div>
    );
  }

  renderCiteModal = (result) => (
    <div className="side-section-container-float">
      <AddToCiteListSection
        caseDigestIds={result}
        closeSection={this.closeModal}
      />
    </div>
  );

  renderRecentCases() {
    const { recentCases } = this.props;
    const { favorites } = this.state;
    return (
      <>
        {recentCases.map((result) => (
          <CaseDigestPreview
            caseDigest={result}
            onAddToList={(e) => this.openCiteModal(e, [result.id])}
            onDownloadPdf={(e) => this.downloadPDF(e, result)}
            hasPDFButton
            hasToListButton
            hasClickableTagList
            isDashboard
            isFavorite={favorites.case_digests !== undefined && favorites.case_digests.some((digest) => digest.id === result.id)}
            onToggleFavorite={() => this.toggleBookmark(null, 'case_digests', result)}
          />
        ))}
      </>
    );
  }

  render() {
    const {
      announcementText, announcementTextEnabled, newlyAddedCasesCount, totalCases, topTags, caseOfTheWeek,
    } = this.props;
    const { mapType } = this.state;
    const recentDate = moment().subtract(90, 'd').format('MM/DD/YYYY');

    return (
      <div className="react-dashboard">
        <div className="left">

          <div className="dashboard-widgets">
            <div className="page-title">
              Dashboard
            </div>
            <div className="page-subtitle" style={{ textTransform: 'uppercase' }}>
              {moment().format('DD MMM YYYY')}
            </div>
            { Boolean(caseOfTheWeek) && (
              <div className="dashboard-widget" style={{ position: 'relative' }}>
                <a className="dashboard-widget-icon video" href={`/case_law/${caseOfTheWeek.slug}?openVideo=true`}>
                  <img alt="video" src="/play.png" />
                </a>
                <div className="dashboard-widget-body cotw-tags">
                  <p>Case of the Week</p>
                  <h3 style={{ fontSize: '2.2rem' }}>{caseOfTheWeek.published_revision.name}</h3>
                  <TagList tags={caseOfTheWeek.published_revision.tag_list.slice(0, 3)} maxVisibleTags={3} isCotw />
                </div>
              </div>
            )}

            <div className="dashboard-widget">
              <div className="dashboard-widget-icon info">
                <img alt="plus" src="/scales.png" />
              </div>
              <div className="dashboard-widget-body">
                <p>This Week&apos;s Top Issues:</p>
                <div className="top-issue-tag">
                  { topTags.length > 0 && topTags.map((tagData) => (
                    <a
                      className="btn btn-link"
                      key={tagData[1].tag_id}
                      href={`/case_law?sort_by=decision_date&page=1&results_per_page=25&tag_ids_query=${tagData[1].tag_id}`}
                    >
                      { tagData[0] }
                    </a>
                  ))}
                </div>
              </div>
            </div>

            <div className="dashboard-widget">
              <div className="dashboard-widget-icon success">
                <img alt="plus" src="/plus.png" />
              </div>
              <div className="dashboard-widget-body">
                <p>Cases Added This week</p>
                <h3>{newlyAddedCasesCount}</h3>
              </div>
            </div>

            <div className="dashboard-widget">
              <div className="dashboard-widget-icon warn">
                <img alt="plus" src="/bulb.png" />
              </div>
              <div className="dashboard-widget-body">
                <p>Total Cases in Database</p>
                <h3>{totalCases}</h3>
              </div>
            </div>
          </div>
          <hr />
          <div className="dashboard-digests">
            <a
              className="page-title"
              href={`/case_law?daterange=after&start_date=${encodeURIComponent(recentDate)}&sort_by=decision_date&page=1&results_per_page=25`}
            >
              Recent Case Law
            </a>
            <div className="page-subtitle">
              Last 90 days
            </div>

            {this.renderRecentCases()}
          </div>
        </div>
        <div className="right">
          { announcementTextEnabled && (
            <div className="announcement-bar">
              <img alt="bulb" src="/bulb.png" />
              { ReactHtmlParser(announcementText) }
            </div>
          )}
          <div className="dashboard-map">
            <div className="header">
              <div>
                <div className="page-title">
                  Jurisdictional Map
                </div>
                <div className="page-subtitle">
                  {totalCases}
                  {' '}
                  {totalCases !== 1 ? 'cases' : 'case'}
                </div>
              </div>

              <div className="react-tabs">
                <button
                  type="button"
                  className={`react-tab ${mapType === 'circuit' ? 'active' : ''}`}
                  onClick={() => this.setMapType('circuit')}
                >
                  Federal
                </button>
                <div className="react-tab-separator" />
                <button
                  type="button"
                  className={`react-tab ${mapType === 'state' ? 'active' : ''}`}
                  onClick={() => this.setMapType('state')}
                >
                  State
                </button>
              </div>
            </div>
            {this.renderCasesMap()}
          </div>

          <hr />

          <div className="dashboard-bookmarks">
            <a href="/favorites" className="bookmarks-title">
              My Bookmarks
            </a>
            <div className="page-subtitle">
              {this.renderBookmarksTotal()}
            </div>
            {this.renderBookmarks()}
          </div>
        </div>

        { this.state.citeModalOpen && this.renderCiteModal(this.state.citeModalResult) }
      </div>
    );
  }
}

Dashboard.propTypes = {
  recentCases: PropTypes.array.isRequired,
  newlyAddedCasesCount: PropTypes.number.isRequired,
  totalCases: PropTypes.number.isRequired,
  topTags: PropTypes.array.isRequired,
  announcementTextEnabled: PropTypes.bool,
  announcementText: PropTypes.string,
  courtCounts: PropTypes.object,
};

Dashboard.defaultProps = {
  announcementTextEnabled: false,
  announcementText: '',
  courtCounts: {},
};

export default Dashboard;
