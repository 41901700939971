import React from 'react';

export function BookChapters({
  mediaItem, item, currentItem, setCurrentItem,
}) {
  if (!item?.media_items || item?.media_items.length === 0) return null;

  // conditionally render different colors for chapter item, active class is !important
  // #E09B00 - active, #515C9E - completed
  const renderChapterClass = (chapter) => {
    const fileTypes = ['Document', 'Article', 'Ebook'];
    const isChapterFinished = Number(chapter?.last_seen_page) / Number(chapter?.total_pages) > 0.9;
    const activeClass = currentItem.id === chapter.id && item.id === mediaItem.id ? 'lc-circle-active' : '';
    const completedClass = (Number(chapter.progress) > 0.9 && !fileTypes.includes(chapter.type)) || isChapterFinished
      ? ' lc-circle-completed' : '';
    return `${activeClass} ${completedClass}`;
  };

  return (
    <ul>
      {item.media_items.map((chapter) => (
        <li key={chapter.id} onClick={() => setCurrentItem(chapter)}>
          <span className={renderChapterClass(chapter)} />
          <div className="lc-chapter-content">
            <p className="lc-chapter-title">{chapter.title}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}
