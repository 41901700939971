// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="district-rules"
>
  <div
    className="select-state"
  >
    <div
      className="empty-description"
    >
      <span>
        There are no district court rules displayed.
      </span>
      <span>
        Select a filter on the left to start.
      </span>
    </div>
    <div
      className="empty-image"
    />
  </div>
</div>
`;

exports[`renders correctly without props 1`] = `
<div
  className="district-rules"
>
  <div
    className="select-state"
  >
    <div
      className="empty-description"
    >
      <span>
        There are no district court rules displayed.
      </span>
      <span>
        Select a filter on the left to start.
      </span>
    </div>
    <div
      className="empty-image"
    />
  </div>
</div>
`;

exports[`renders correctly without props with loader 1`] = `
<div
  className="district-rules"
>
  <div
    className="select-state"
  >
    <div
      className="empty-description"
    >
      <span>
        There are no district court rules displayed.
      </span>
      <span>
        Select a filter on the left to start.
      </span>
    </div>
    <div
      className="empty-image"
    />
  </div>
</div>
`;
