// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<ul>
  <li
    onClick={[Function]}
  >
    <span
      className="lc-circle-active "
    />
    <div
      className="lc-chapter-content"
    >
      <p
        className="lc-chapter-title"
      >
        Case of the Week #22:  Oracle USA, Inc. v. Rimini Street, Inc.
      </p>
    </div>
  </li>
</ul>
`;

exports[`renders correctly without props 1`] = `null`;
