import React, { useState } from 'react';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import api from '../../services/api';

function splitFullName(name) {
  const nameArr = name.split(/\s+/);
  if (nameArr.length === 1) return { first_name: nameArr[0] };

  return {
    first_name: nameArr.slice(0, -1).join(' '),
    last_name: nameArr.pop(),
  };
}

export default function CreateProfile({
  data, currentUser, onChange, onSubmit,
}) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const validateField = async (fieldName, currentData) => {
    let currentError = '';
    const value = currentData[fieldName];
    let valid = false;

    switch (fieldName) {
      case 'email':
        if (!value) currentError = 'Email missing';
        else {
          valid = Boolean(value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
          if (!valid) currentError = 'Email is invalid.';
          else if (valid && data.plan.plan_type === 'trial') {
            await api.check_email(value)
              .then((response) => {
                if (response.status === 'ERROR') currentError = response.message;
              });
          }
        }
        break;
      case 'name':
        valid = !!value;
        if (!valid) currentError = 'User Full Name missing';
        break;
      case 'company':
        valid = !!value;
        if (!valid) currentError = 'Organization / Firm name missing';
        break;
      case 'password':
        valid = currentUser || value.length >= 8;
        if (!valid) currentError = 'Password too short (minimum 8 characters)';
        break;
      case 'password2':
        valid = currentUser || currentData.password === value;
        if (!valid) currentError = 'Passwords do not match';
        break;
      case 'phone':
        valid = !!value;
        if (!valid) currentError = 'Phone missing';
        break;
      default:
        break;
    }

    setFormErrors({ ...formErrors, [fieldName]: currentError });
    return valid;
  };

  const isEmpty = () => {
    // define required fields
    const currentFields = ['email', 'name', 'company'];
    if (data.plan.name === 'Trial') currentFields.push('phone');
    const newFields = [...currentFields, 'password', 'password2'];
    const currentErrors = { ...formErrors };
    const emptyFields = (currentUser ? currentFields : newFields).map((field) => {
      if (data[field] === '') currentErrors[field] = 'Please complete this required field.';
      return data[field] === '';
    });
    setFormErrors(currentErrors);
    return emptyFields.find((f) => f === true);
  };

  const handleChange = (e) => {
    const field = e.target.id;
    onChange((prevData) => {
      const newData = { ...prevData, [field]: e.target.value };
      validateField(field, newData);
      return newData;
    });
  };

  const handleCheckboxChange = (e) => {
    const field = e.target.id;
    onChange({ ...data, [field]: e.target.checked });
  };

  const handleSubmit = () => {
    const hasErrors = Object.values(formErrors).filter((e) => e).length;
    const hasEmptyFields = isEmpty();
    if (hasErrors || hasEmptyFields) return;

    if (data.permission === 'seat' || data.permission === 'billing_and_seat') {
      const user = { email: data.email, ...splitFullName(data.name) };
      onChange({ ...data, seats: [user] });
    } else {
      const seats = currentUser ? data.seats.filter((seat) => seat.email !== currentUser.email) : data.seats;
      if (seats.length === 0) seats.push({ email: '', first_name: '', last_name: '' });
      onChange({ ...data, seats, number_of_seats: seats.length });
    }

    onSubmit();
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <div className="register-form-title">Account Details</div>
          <div className={`form-group ${formErrors.name ? 'has-error' : ''}`}>
            <input
              className="form-control"
              type="text"
              name="name"
              id="name"
              value={data.name}
              onChange={handleChange}
              placeholder="Full Name"
            />
            { formErrors.name && <p className="help-block">{formErrors.name}</p> }
          </div>

          <div className={`form-group ${formErrors.email ? 'has-error' : ''}`}>
            <input
              className="form-control"
              type="email"
              name="email"
              id="email"
              value={data.email}
              onChange={handleChange}
              placeholder="Email Address"
              disabled={Boolean(currentUser && currentUser.account.primary_email)}
            />
            { formErrors.email && <p className="help-block">{formErrors.email}</p> }
          </div>

          <div className={`form-group ${formErrors.company ? 'has-error' : ''}`}>
            <input
              className="form-control"
              type="text"
              name="company"
              id="company"
              value={data.company}
              onChange={handleChange}
              placeholder="Organization / Firm Name"
              disabled={data.plan.plan_type === 'charge'}
            />
            { formErrors.company && <p className="help-block">{formErrors.company}</p> }
          </div>

          { data.plan.plan_type !== 'charge' && (
          <div className={`form-group ${formErrors.phone ? 'has-error' : ''}`}>
            <input
              className="form-control"
              type="number"
              name="phone"
              id="phone"
              value={data.phone}
              onChange={handleChange}
              placeholder={`Phone Number${data.plan.name !== 'Trial' ? ' Optional' : ''}`}
            />
            { formErrors.phone && <p className="help-block">{formErrors.phone}</p> }
          </div>
          )}

          <label className="register-checkbox">
            <input
              type="checkbox"
              checked={data.newsletter}
              name="newsletter"
              id="newsletter"
              onChange={handleCheckboxChange}
            />
            Sign me up for eDiscovery Assistant blog
          </label>
        </div>
        { !currentUser && (
          <div className="col-sm-6">
            <div className="register-form-title">Create Password</div>
            <div className={`form-group ${formErrors.password ? 'has-error' : ''}`}>
              {passwordVisible ? (
                <div className="input-group">
                  <input
                    className="form-control"
                    type="text"
                    name="password"
                    value={data.password}
                    onChange={handleChange}
                    placeholder="Password"
                    id="password"
                  />
                  <span className="input-group-addon" onClick={() => setPasswordVisible(false)}>
                    <FaRegEyeSlash />
                  </span>
                </div>
              ) : (
                <div className="input-group">
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    value={data.password}
                    onChange={handleChange}
                    placeholder="Password"
                    id="password"
                  />
                  <span className="input-group-addon" onClick={() => setPasswordVisible(true)}>
                    <FaRegEye />
                  </span>
                </div>
              )}
              { formErrors.password && <p className="help-block">{formErrors.password}</p> }
              <div className="note">
                Please add at least 1 Uppercase and 1 symbol for increasing password strength.
              </div>
            </div>

            <div className={`form-group ${formErrors.password2 ? 'has-error' : ''}`}>
              <input
                className="form-control"
                type="password"
                name="password2"
                value={data.password2}
                onChange={handleChange}
                placeholder="Confirm Password"
                id="password2"
              />
              { formErrors.password2 && <p className="help-block">{formErrors.password2}</p> }
            </div>
          </div>
        ) }
      </div>

      <div className="register-form-actions">
        <button type="button" className="btn btn-submit" onClick={handleSubmit}>Continue</button>
      </div>
    </>
  );
}
