import React, { useState } from 'react';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { FadeLoader } from 'react-spinners';
import { GoSearch } from 'react-icons/go';
import TagList from '../../tags/TagList';

export default function CategorizedRules({
  ruleCategories,
  toggleRuleBookmark,
  selectedRules,
  favorites,
  loading,
}) {
  const [issuesQuery, setIssuesQuery] = useState('');

  const isMatchQuery = (rule) => issuesQuery === '' || rule.tag_list.find((tag) => tag.toLowerCase().includes(issuesQuery.toLowerCase()));

  const renderSearch = () => (
    <div className="case-search">
      <input type="text" placeholder="Search Issues, try: Sanctions" onChange={(e) => setIssuesQuery(e.target.value)} value={issuesQuery} />
      <GoSearch />
      <span style={{ flex: 1 }} />
    </div>
  );

  if (loading) {
    return (
      <div className="federal-rules">
        <div className="loader">
          <FadeLoader color="rgba(0, 14, 87, 1)" />
          <div className="name">Loading</div>
        </div>
      </div>
    );
  }

  return (
    <div className="federal-rules">
      <div className="federal-rule-list">
        {ruleCategories.map((category, index) => (
          <>
            <div className="title">
              {category.name}
              <span className="selected-category-count">{category.rules.length}</span>
              { index === 0 && renderSearch()}
            </div>
            <div className="results">
              {category.rules.filter((r) => isMatchQuery(r)).map((rule) => (
                <a
                  className="result"
                  key={rule.rule_id}
                  href={`/${selectedRules === 'ABA' ? 'aba_model' : selectedRules?.toLowerCase()}_rules/${rule.rule_id}`}
                >
                  <div className="top">
                    <div className="result-number">
                      <div style={rule.title
                        ? { flex: 'none', maxWidth: '50%' } : { maxWidth: '100%' }}
                      >
                        {rule.number + (rule.title && ' - ')}
                      </div>
                      &nbsp;
                      <div style={{ fontWeight: 400 }}>{rule.title}</div>
                    </div>
                    {favorites.rules && favorites.rules.length > 0 && favorites.rules.some(
                      (favoriteRule) => favoriteRule?.id === rule.rule_id,
                    ) ? (
                      <FaBookmark
                        onClick={(e) => toggleRuleBookmark(e, rule)}
                      />
                      ) : (
                        <FaRegBookmark
                          className="initially-hidden"
                          onClick={(e) => toggleRuleBookmark(e, rule)}
                        />
                      )}
                  </div>
                  <TagList className="result-tags" tags={rule.tag_list} />
                </a>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
