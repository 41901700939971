// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="federal-rules-navigator"
>
  <div
    className="category-list"
  >
    <div
      className="category"
      onClick={[Function]}
    >
      <div
        className="name"
      >
        All
      </div>
      <div
        className="rule-count"
      />
    </div>
    <div
      className="category"
      onClick={[Function]}
    >
      <div
        className="name"
      >
        Federal Rule Category #1
      </div>
      <div
        className="rule-count"
      >
        2
      </div>
    </div>
  </div>
</div>
`;
