import React from 'react';
import renderer from 'react-test-renderer';

import { FileContent } from '../../../learning_center/DetailsContent/FileContent';
import { documentItem } from '../../__mocks__/lc-data';

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ message: {} }) });

it('renders correctly with props', () => {
  const tree = renderer.create(
    <FileContent
      mediaItem={documentItem}
      tab="about"
      setTab={() => {}}
      isAdmin
      linkedContent={[]}
    />,
  ).toJSON();
  expect(tree).toMatchSnapshot();
});
